<template>
  <validation-provider v-slot="{ errors }" name="Client" rules="required">
    <v-combobox
      v-model="selectedClient"
      v-required
      label="Client"
      :loading="isClientsSearchLoading"
      :search-input.sync="clientNameSearchInput"
      :error-messages="errors"
      :items="clients"
      item-value="id"
      item-text="name"
      hide-no-data
      no-filter
      @keyup.enter="searchClients"
      @keyup.enter.native.prevent
      @keydown.enter.native.prevent
    >
      <!-- <template #append-outer>
        <v-btn v-if="!internalSelectedClientItem" small outlined color="primary">New Client</v-btn>
        <v-btn v-else small outlined color="primary">Edit Client</v-btn>
      </template> -->

      <template #item="data">
        <div>
          <!-- TODO: build text highlighter -->
          <span>{{ data.item.name }}</span>
          <span class="text-caption text-medium-emphasis grey--text ml-1">
            (Scoro Client ID: {{ data.item.crmId }})
          </span>
        </div>
      </template>
    </v-combobox>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { useDebounceFn } from '@vueuse/core';

import ClientService from '../services/ClientService';
import Flash from '../helpers/Flash';

const flash = new Flash('.flash--main');

export default {
  components: {
    ValidationProvider,
  },
  props: {
    initialClient: {
      type: Object,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      clients: [],
      clientNameSearchInput: '',
      selectedClient: {
        id: null,
        name: null,
        contacts: [],
      },
      isClientsSearchLoading: false,
    };
  },

  watch: {
    initialClient(newClient) {
      this.selectedClient = newClient;
    },

    selectedClient(newClientItemOrName) {
      this.$emit('update:selected-client', {
        id: newClientItemOrName?.id | null,
        name: newClientItemOrName?.name || newClientItemOrName,
        contacts: newClientItemOrName?.contacts || [],
      });
    },

    clientNameSearchInput: useDebounceFn(
      function () {
        this.searchClients();
      },
      600,
      { maxWait: 1200 },
    ),
  },

  methods: {
    async searchClients() {
      if (!this.clientNameSearchInput) {
        return;
      }

      const client = this.clients.find((client) => client.name === this.clientNameSearchInput);
      if (client?.id && this.selectedClient?.id && client.id === this.selectedClient.id) {
        return;
      }

      if (this.clientNameSearchInput && !this.clientNameSearchInput.trim()) {
        return;
      }

      flash.clear();
      this.isClientsSearchLoading = true;

      try {
        this.clients = await ClientService.listClients({ client_name_pattern: this.clientNameSearchInput });
      } catch (err) {
        console.error(err);

        flash.clear();
        flash.flash('Client search failed. Please reload the page and try again.', 'error', true);
      } finally {
        this.isClientsSearchLoading = false;
      }
    },
  },
};
</script>
