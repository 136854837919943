import { useDebounceFn } from '@vueuse/core';
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import Vue from 'vue';
import _ from 'lodash';

import {
  ITEMS_PER_PAGE_DEFAULT,
  ITEMS_PER_PAGE_OPTIONS,
  ORDER_DEFAULT_STATUS_GROUPS,
  ORDER_TABLE_CONFIG_LOCAL_STORAGE_KEY,
} from '../../config';
import { arrayToCommaString, dataTableLoadData } from '../../helpers/functions';
import { page } from '../../helpers/page';
import { utcDateTimeToLocalString } from '../../helpers/dates';
import OrderService from '../../services/OrderService';
import Vuetify from '../../helpers/vuetify';

page('/orders', function () {
  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],

    directives: {
      // https://codepen.io/uglyhobbitfeet/pen/ExxaNGO
      sortableHeader: {
        inserted: function (el, binding, _) {
          const tableHeaderEl = el.querySelector('thead > tr');
          new Sortable(tableHeaderEl, {
            animation: 150,
            easing: 'cubic-bezier(1, 0, 0, 1)',
            draggable: 'th',
            ...binding.value,
          });
        },
      },
    },
    data() {
      return {
        id: 0,
        isDataTableExpanded: false,
        expandedOrders: [],

        scrollToTopFab: false,

        page: 1,
        pageCount: 0,
        serverItemsLength: 0,
        options: {},

        footerProps: {
          itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
          showFirstLastPage: true,
          showCurrentPage: true,
        },

        search: '',

        loading: false,
        error: false,

        orderConfig: {},
        tableConfig: {
          itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
          orderTypes: [],
          orderStatusGroups: [],
          orderStatusCodes: [],
          managers: [],
          orderFilters: [],
          headers: [],
          displayedHeaders: [],
        },

        headers: [],
        headersMap: {
          id: {
            position: 0,
            key: 'id',
            text: 'Order #',
            sortable: true,
            value: 'id',
            groupable: false,
            displayed: true,
          },
          priority: {
            position: 1,
            key: 'priority',
            text: 'Priority',
            sortable: true,
            value: 'priority.name',
            groupable: true,
            displayed: true,
          },
          deadline: {
            position: 2,
            key: 'deadline',
            text: 'Deadline',
            sortable: true,
            value: 'deadline',
            groupable: false,
            date: true,
            displayed: true,
          },
          order_type: {
            position: 3,
            key: 'order_type',
            text: 'Order type',
            sortable: true,
            value: 'order_type.name',
            groupable: true,
            displayed: true,
          },
          status: {
            position: 4,
            key: 'status',
            text: 'Status',
            sortable: true,
            value: 'status.name',
            groupable: true,
            displayed: true,
          },
          client: {
            position: 5,
            key: 'client',
            text: 'Client',
            sortable: true,
            value: 'client',
            groupable: true,
            displayed: true,
          },
          manager: {
            position: 6,
            key: 'manager',
            text: 'Manager',
            sortable: true,
            value: 'manager.name',
            groupable: true,
            displayed: true,
          },
          created_at: {
            position: 7,
            key: 'created_at',
            text: 'Created at',
            sortable: true,
            value: 'created_at',
            groupable: false,
            date: true,
            displayed: true,
          },
          print_size: {
            position: 8,
            key: 'print_size',
            text: 'Print size',
            sortable: true,
            value: 'print_size',
            groupable: false,
            displayed: true,
          },
          print_count: {
            position: 9,
            key: 'print_count',
            text: 'Print count',
            sortable: true,
            value: 'print_count',
            groupable: false,
            displayed: true,
          },
          print_file: {
            position: 10,
            key: 'print_file',
            text: 'Print file',
            sortable: true,
            value: 'print_file',
            groupable: false,
            displayed: true,
          },
          print_material: {
            position: 11,
            key: 'print_material',
            text: 'Print material',
            sortable: true,
            value: 'print_material.value',
            groupable: true,
            displayed: true,
          },
          print_side: {
            position: 12,
            key: 'print_side',
            text: 'Print side',
            sortable: true,
            value: 'print_side.value',
            groupable: true,
            displayed: true,
          },
          print_laminate: {
            position: 13,
            key: 'print_laminate',
            text: 'Print laminate',
            sortable: true,
            value: 'print_laminate.value',
            groupable: true,
            displayed: true,
          },
          print_device: {
            position: 14,
            key: 'print_device',
            text: 'Device',
            sortable: true,
            value: 'print_device.value',
            groupable: true,
            displayed: true,
          },
          post_processing: {
            position: 15,
            key: 'post_processing',
            text: 'Post processing',
            sortable: true,
            value: 'post_processing.values',
            groupable: false,
            displayed: true,
          },
          actions: {
            position: 16,
            key: 'actions',
            text: 'Actions',
            value: 'actions',
            groupable: false,
            displayed: true,
          },
          expand: {
            position: 17,
            key: 'expand',
            text: 'Show more',
            value: 'data-table-expand',
            groupable: false,
            displayed: true,
          },
        },

        orders: [],
      };
    },

    computed: {
      headersMapValues() {
        return Object.values(this.headersMap);
      },

      displayedHeaders() {
        return this.headers.filter((h) => h.displayed);
      },
    },

    async created() {
      // Load order configuration
      await this.getOrderConfig();

      // If configuration load has failed
      if (_.isEmpty(this.orderConfig)) {
        this.error = true;
        return;
      }

      // Order table configuration
      this.defaultTableConfig();
      this.defaultDisplayedHeaders();
      this.restoreTableConfig();
      this.orderHeaders();

      this.initItemsPerPage();

      // Order data
      await this.queryOrderData();

      // Watchers
      this.$watch('tableConfig', this.saveTableConfig, { deep: true });

      this.$watch('tableConfig.orderTypes', this.queryOrderData, { deep: true });
      this.$watch('tableConfig.orderStatusGroups', this.queryOrderData, { deep: true });
      this.$watch('tableConfig.orderFilters', this.queryOrderData, { deep: true });
      this.$watch('tableConfig.orderStatusCodes', this.queryOrderData, { deep: true });
      this.$watch('tableConfig.managers', this.queryOrderData, { deep: true });
      this.$watch('search', useDebounceFn(this.queryOrderDataOnSearchChange, 600, { maxWait: 1200 }));
      this.$watch('options', this.queryOrderData, { deep: true }); // On pagination change

      this.$watch('tableConfig.headers', this.orderHeaders, { deep: true });
      this.$watch('tableConfig.displayedHeaders', this.updateDisplayedHeaders, { deep: true });

      // Keyboard shortcuts
      window.addEventListener('keydown', this.navigatePaginationKeyboardShortcuts);
    },

    methods: {
      utcDateTimeToLocalString,

      initItemsPerPage() {
        this.options.itemsPerPage = this.tableConfig.itemsPerPage;
      },

      updateItemsPerPage(val) {
        this.tableConfig.itemsPerPage = val;
        this.options.itemsPerPage = val;
      },

      toggleDataTableExpand() {
        this.isDataTableExpanded = !this.isDataTableExpanded;

        if (this.isDataTableExpanded) {
          this.expandDataTable();
        } else {
          this.collapseDataTable();
        }
      },

      expandDataTable() {
        this.expandedOrders = this.orders;
      },

      collapseDataTable() {
        this.expandedOrders = [];
      },

      // Field colors
      getOrderPriorityFieldColor(item) {
        const colors = {
          urgent: 'orange',
        };
        return colors[item.priority.code] || 'white';
      },

      getOrderStatusFieldColor(item) {
        const colors = {
          ripped: 'orange',
          printed: 'green',
          error: 'red',
        };
        return colors[item.status.code] || 'white';
      },

      getOrderDeadlineFieldColor(item) {
        const colors = {
          DUE_TODAY: 'orange',
          OVERDUE: 'red',
        };

        return colors[item.deadline_status] || 'white';
      },

      resetView() {
        if (!confirm('Are you sure you want to reset the order table filters?')) return;
        localStorage.removeItem(ORDER_TABLE_CONFIG_LOCAL_STORAGE_KEY);
        location.reload();
      },

      postProcessOrderData() {
        this.orders.forEach((order) => {
          order.post_processing = {
            values: arrayToCommaString(order.post_processing?.map((v) => v.value)),
          };
        });
      },

      async getOrderConfig() {
        const boundDataTableLoadData = dataTableLoadData.bind(this);
        await boundDataTableLoadData('orderConfig', OrderService.getOrderConfig);
      },

      async queryOrderData() {
        const { groupBy, groupDesc, sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.orders = [];

        const boundDataTableLoadData = dataTableLoadData.bind(this);
        await boundDataTableLoadData(
          'orders',
          () =>
            OrderService.listOrders({
              // Order filters
              order_types: this.tableConfig.orderTypes,
              order_status_groups: this.tableConfig.orderStatusGroups,
              order_statuses: this.tableConfig.orderStatusCodes || [],
              managers: this.tableConfig.managers || [],
              order_filters: this.tableConfig.orderFilters || [],
              // Search
              search: this.search || null,
              // Sort
              sort_by: groupBy.concat(sortBy) || null,
              sort_directions: groupDesc.concat(sortDesc).map((v) => (v ? 'desc' : 'asc')) || null,
              // Pagination
              page: page,
              limit: itemsPerPage === -1 ? null : itemsPerPage, // -1 is 'all' items
            }),
          'orders',
          true,
        );

        this.postProcessOrderData();

        if (this.isDataTableExpanded) {
          this.expandDataTable();
        }
      },

      async queryOrderDataFromSearchBtn() {
        await this.queryOrderData();

        this.goToPage(1);
      },

      async queryOrderDataOnSearchChange(newSearchValue, oldSearchValue) {
        await this.queryOrderData();

        if (newSearchValue !== oldSearchValue) {
          this.goToPage(1);
        }
      },

      goToPage(page) {
        this.options.page = parseInt(page, 10) || 1;
      },

      defaultTableConfig() {
        this.tableConfig.orderTypes = this.orderConfig.types.map((v) => v.id);
        this.tableConfig.orderStatusGroups = ORDER_DEFAULT_STATUS_GROUPS;
        this.tableConfig.headers = this.headersMapValues;
      },

      restoreTableConfig() {
        const tableConfig = JSON.parse(localStorage.getItem(ORDER_TABLE_CONFIG_LOCAL_STORAGE_KEY));
        if (!tableConfig) return;
        this.tableConfig = tableConfig;
      },

      saveTableConfig() {
        localStorage.setItem(ORDER_TABLE_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(this.tableConfig));
      },

      sortTableHeader(event) {
        // Find element that is being moved
        const elToBeMoved = this.tableConfig.headers.find((el) => el.key == this.displayedHeaders[event.oldIndex].key);
        const elMoveTo = this.tableConfig.headers.find((el) => el.key == this.displayedHeaders[event.newIndex].key);

        const oldPosition = elToBeMoved.position;
        const newPosition = elMoveTo.position;

        // For all elements that are on the right side to newIndex, re-order position starting from newIndex + 1
        let right = this.tableConfig.headers.filter((el) => el.position > newPosition && el.key != elToBeMoved.key);

        // For all elements that are on the left side, re-order position starting from 0, increment by one
        let left = this.tableConfig.headers.filter((el) => el.position < newPosition && el.key != elToBeMoved.key);

        // Elements with the same position as elToBeMoved should go
        // on the left or the right depending on moving direction
        const center = this.tableConfig.headers.filter((el) => el.position == newPosition && el.key != elToBeMoved.key);
        if (oldPosition < newPosition) {
          left = left.concat(center);
        } else {
          right = center.concat(right);
        }

        let incrementRight = newPosition + 1;
        right.forEach((el) => {
          this.tableConfig.headers.find((h) => h.key == el.key).position = incrementRight;
          incrementRight++;
        });

        let incrementLeft = 0;
        left.forEach((el) => {
          this.tableConfig.headers.find((h) => h.key == el.key).position = incrementLeft;
          incrementLeft++;
        });

        // Set new element position
        this.tableConfig.headers.find((h) => h.key == elToBeMoved.key).position = newPosition;

        // Column drag does not work without it. Is it :key for UI refresh?
        this.id++;
      },

      defaultDisplayedHeaders() {
        this.tableConfig.displayedHeaders = this.headersMapValues.map((v) => v.value);
      },

      updateDisplayedHeaders() {
        this.tableConfig.headers = this.tableConfig.headers.map((h) => {
          h.displayed = this.tableConfig.displayedHeaders.includes(h.value);
          return h;
        });
      },

      orderHeaders() {
        this.headers = _.orderBy(this.tableConfig.headers, ['position'], ['asc']);
      },

      navigatePaginationKeyboardShortcuts(event) {
        if (event.altKey && event.key === 'ArrowLeft') {
          if (this.page != 1) {
            this.page = this.page - 1;
          }
        } else if (event.altKey && event.key === 'ArrowRight') {
          if (this.page < this.pageCount) {
            this.page = this.page + 1;
          }
        }
      },

      onScroll(e) {
        if (typeof window === 'undefined') return;
        const top = window.pageYOffset || e.target.scrollTop || 0;
        this.scrollToTopFab = top > 20;
      },

      toTop() {
        this.$vuetify.goTo(0);
      },
    },
  });

  app.$mount('#app');
});
