import Vue from 'vue';
import _ from 'lodash';

import { ITEMS_PER_PAGE_DEFAULT, ITEMS_PER_PAGE_OPTIONS, ORDER_MATERIAL_FIELDS } from '../../config';
import { dataTableLoadData } from '../../helpers/functions';
import { page } from '../../helpers/page';
import Flash from '../../helpers/Flash';
import OrderService from '../../services/OrderService';
import PricesService from '../../services/PricesService';
import Vuetify from '../../helpers/vuetify';

// Highly inefficient and bag code
// *Apologies for myself in the future*
page('/prices/clients', function () {
  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],
    data() {
      return {
        page: 1,
        pageCount: 0,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        footerProps: {
          itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
        },
        search: '',
        loading: false,
        error: false,
        headers: [
          {
            text: 'Name',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Contact Person',
            sortable: true,
            value: 'contact_person',
          },
          {
            text: 'Contact Phone',
            sortable: true,
            value: 'contact_phone',
          },
          {
            text: 'Contact Email',
            sortable: true,
            value: 'contact_email',
          },
          {
            text: 'VAT Number',
            sortable: true,
            value: 'vat_number',
          },
          {
            text: 'Total Price Modifier',
            sortable: true,
            value: 'total_price_modifier',
          },
          {
            text: 'Actions',
            value: 'id',
          },
        ],
        clients: [],
        materialPricesItemsPerPage: -1,
        materialPricesHeaders: [
          {
            text: 'Order Type',
            sortable: true,
            value: 'order_type.name',
          },
          {
            text: 'Material Type',
            sortable: true,
            value: 'field.name',
          },
          {
            text: 'Material Name',
            sortable: true,
            value: 'value',
          },
          {
            text: 'Total Price Modifier',
            sortable: true,
            value: 'total_price_modifier',
          },
          {
            text: 'Total Price Override',
            sortable: true,
            value: 'total_price_override',
          },
          {
            text: 'Unit',
            sortable: true,
            value: 'meta.unit',
          },
          {
            text: 'Actions',
            value: 'id',
          },
        ],
        // Client
        newClientDialog: false,
        newClientSubmitting: false,
        editClientDialog: false,
        editClientSubmitting: false,
        newClientModel: {},
        editClientModel: {},
        defaultClientModel: {
          id: '',
          name: '',
          contactPerson: '',
          contactPhone: '',
          contactEmail: '',
          vatNumber: '',
          totalPriceModifier: '',
          notes: '',
        },
        orderConfigLoading: false,
        newMaterialFixedPriceDialog: false,
        editMaterialFixedPriceDialog: false,
        newMaterialFixedPriceSubmitting: false,
        editMaterialFixedPriceSubmitting: false,
        materialFixedPriceModel: {},
        defaultMaterialFixedPriceModel: {
          // clientId + materialName == PK
          clientId: '',
          orderType: '',
          materialType: '',
          materialName: '',
          totalPriceModifier: '',
          totalPriceOverride: '',
        },
        // Material fixed price select fields
        // Get select field config; split into three groups
        // Dynamically update downstream groups on item change
        orderSelectFieldConfig: {},
        listOrderTypes: [],
        listMaterialTypes: [],
        listPrintMaterial: [],
        priceOverridePlaceholder: '',
      };
    },
    async created() {
      this.getAllClients();
      this.getOrdersSelectFields();
      // Watchers
      this.$watch('materialFixedPriceModel.orderType', this.changeMaterialType);
      this.$watch('materialFixedPriceModel.materialType', this.updateMaterialList);
      this.$watch('materialFixedPriceModel.materialName', this.updateMaterialPricePlaceholder);
    },
    methods: {
      async getAllClients() {
        const boundDataTableLoadData = dataTableLoadData.bind(this);
        await boundDataTableLoadData('clients', PricesService.getAll);
      },
      openNewClientDialog() {
        this.newClientDialog = true;
        this.newClientModel = { ...this.defaultClientModel }; // dummy model
      },
      closeNewClientDialog() {
        this.newClientDialog = false;
        this.newClientModel = { ...this.defaultClientModel };
        try {
          const flashError = new Flash('.flash--new-client-dialog');
          flashError.clear();
        } catch {
          return null;
        }
      },
      async submitNewClient(event) {
        event.preventDefault();
        this.newClientSubmitting = true;
        const flashSuccess = new Flash('.flash--main');
        const flashError = new Flash('.flash--new-client-dialog');
        flashSuccess.clear();
        flashError.clear();
        try {
          await PricesService.newClient(new FormData(event.target));
          this.closeNewClientDialog();
          this.getAllClients();
          flashSuccess.flash('Saved', 'success', true);
        } catch (err) {
          console.error(err);
          if (err.response?.data?.errors && err.response.data.errors.length) {
            err.response.data.errors.forEach((errorMessage) => {
              flashError.flash(errorMessage, 'error');
            });
          } else {
            flashError.flash('Could not save. Please reload the page and try again', 'error');
          }
        } finally {
          this.newClientSubmitting = false;
        }
      },
      openEditClientDialog(client) {
        this.editClientDialog = true;
        this.editClientModel = {
          id: client.id,
          name: client.name,
          contactPerson: client.contact_person,
          contactPhone: client.contact_phone,
          contactEmail: client.contact_email,
          vatNumber: client.vat_number,
          totalPriceModifier: client.total_price_modifier,
          notes: client.notes,
        };
      },
      closeEditClientDialog() {
        this.editClientDialog = false;
        this.editClientModel = { ...this.defaultClientModel };
        try {
          const flashError = new Flash('.flash--edit-client-dialog');
          flashError.clear();
        } catch {
          return null;
        }
      },
      async submitEditClient(event) {
        event.preventDefault();
        this.editClientSubmitting = true;
        const flashSuccess = new Flash('.flash--main');
        const flashError = new Flash('.flash--edit-client-dialog');
        flashSuccess.clear();
        flashError.clear();
        try {
          await PricesService.editClient(new FormData(event.target));
          this.closeEditClientDialog();
          this.getAllClients();
          flashSuccess.flash('Edited', 'success', true);
        } catch (err) {
          console.error(err);
          if (err.response?.data?.errors && err.response.data.errors.length) {
            err.response.data.errors.forEach((errorMessage) => {
              flashError.flash(errorMessage, 'error');
            });
          } else {
            flashError.flash('Could not edit. Please reload the page and try again', 'error');
          }
        } finally {
          this.editClientSubmitting = false;
        }
      },
      async deleteClient(event) {
        event.preventDefault();
        if (!confirm('Are you sure you want to delete the client?')) {
          return;
        }
        const deleteClientBtnEl = event.target.querySelector('button');
        deleteClientBtnEl.disabled = true;
        const flash = new Flash();
        flash.clear();
        try {
          await PricesService.deleteClient(new FormData(event.target));
          this.getAllClients();
          flash.flash('Deleted', 'success', true);
        } catch (err) {
          console.error(err);
          if (err.response?.data?.errors && err.response.data.errors.length) {
            err.response.data.errors.forEach((errorMessage) => {
              flash.flash(errorMessage, 'error');
            });
          } else {
            flash.flash('Could not delete. Please reload the page and try again', 'error');
          }
        } finally {
          deleteClientBtnEl.disabled = false;
        }
      },
      getClientRef(clientId) {
        for (const [i, client] of this.clients.entries()) {
          if (client.id == clientId) {
            return this.clients[i];
          }
        }
        return null;
      },
      async refreshClient(clientId) {
        try {
          this.loading = true;
          const client = this.getClientRef(clientId);
          if (client) {
            const clientData = await PricesService.get(clientId);
            // Update clients select field values
            if (!_.isEmpty(clientData.select_field_values)) {
              client.select_field_values = clientData.select_field_values;
            } else {
              client.select_field_values = [];
            }
            // Update UI
            this.$forceUpdate;
          }
          this.error = false;
        } catch (err) {
          console.error(err);
          // Expected error messages
          if (err.response && err.response.status === 404) return;
          // If session has expired, restart page. It will prompt to login again
          if (err.response && err.response.status === 401) location.reload();
          this.error = true;
        } finally {
          this.loading = false;
        }
      },
      // Material Fixed Price
      // New
      openNewMaterialFixedPriceDialog(client) {
        this.newMaterialFixedPriceDialog = true;
        this.materialFixedPriceModel = { ...this.defaultMaterialFixedPriceModel };
        // Populate client_id before creating FormData
        this.materialFixedPriceModel.clientId = client.id;
      },
      closeNewMaterialFixedPriceDialog() {
        this.newMaterialFixedPriceDialog = false;
        this.materialFixedPriceModel = { ...this.defaultMaterialFixedPriceModel };
        try {
          const flashError = new Flash('.flash--new-material-fixed-price');
          flashError.clear();
        } catch {
          return null;
        }
      },
      // Edit
      openEditMaterialFixedPriceDialog(client, material) {
        this.editMaterialFixedPriceDialog = true;
        this.materialFixedPriceModel = {
          clientId: client.id,
          orderType: material.order_type.id,
          materialType: material.field.id,
          materialName: material.id,
          totalPriceModifier: material.total_price_modifier,
          totalPriceOverride: material.total_price_override,
        };
      },
      closeEditMaterialFixedPriceDialog() {
        this.editMaterialFixedPriceDialog = false;
        this.materialFixedPriceModel = { ...this.defaultMaterialFixedPriceModel };
        try {
          const flashError = new Flash('.flash--edit-material-fixed-price');
          flashError.clear();
        } catch {
          return null;
        }
      },
      async submitNewMaterialFixedPrice(event) {
        event.preventDefault();
        this.newMaterialFixedPriceSubmitting = true;
        const flashSuccess = new Flash('.flash--main');
        const flashError = new Flash('.flash--new-material-fixed-price');
        flashSuccess.clear();
        flashError.clear();
        try {
          // Manual value mapping due to v-select submits both value and text props
          const form = new FormData(event.target);
          form.set('order_type_id', this.materialFixedPriceModel.orderType);
          form.set('material_type_id', this.materialFixedPriceModel.materialType);
          form.set('material_name_id', this.materialFixedPriceModel.materialName);
          await PricesService.newFixedMaterialPrice(form);
          this.refreshClient(this.materialFixedPriceModel.clientId);
          this.closeNewMaterialFixedPriceDialog();
          flashSuccess.flash('Saved', 'success', true);
        } catch (err) {
          console.error(err);
          if (err.response?.data?.errors && err.response.data.errors.length) {
            err.response.data.errors.forEach((errorMessage) => {
              flashError.flash(errorMessage, 'error');
            });
          } else {
            flashError.flash('Could not save. Please reload the page and try again', 'error');
          }
        } finally {
          this.newMaterialFixedPriceSubmitting = false;
        }
      },
      async submitEditMaterialFixedPrice(event) {
        event.preventDefault();
        this.editMaterialFixedPriceSubmitting = true;
        const flashSuccess = new Flash('.flash--main');
        const flashError = new Flash('.flash--edit-material-fixed-price');
        flashSuccess.clear();
        flashError.clear();
        try {
          // Manual value mapping due to v-select submits both value and text props
          const form = new FormData(event.target);
          form.set('order_type_id', this.materialFixedPriceModel.orderType);
          form.set('material_type_id', this.materialFixedPriceModel.materialType);
          form.set('material_name_id', this.materialFixedPriceModel.materialName);
          await PricesService.editFixedMaterialPrice(form);
          this.refreshClient(this.materialFixedPriceModel.clientId);
          this.closeEditMaterialFixedPriceDialog();
          flashSuccess.flash('Saved', 'success', true);
        } catch (err) {
          console.error(err);
          if (err.response?.data?.errors && err.response.data.errors.length) {
            err.response.data.errors.forEach((errorMessage) => {
              flashError.flash(errorMessage, 'error');
            });
          } else {
            flashError.flash('Could not save. Please reload the page and try again', 'error');
          }
        } finally {
          this.editMaterialFixedPriceSubmitting = false;
        }
      },
      async deleteMaterialFixedPrice(event) {
        event.preventDefault();
        if (!confirm('Are you sure you want to delete the fixed price?')) {
          return;
        }
        const deleteBtn = event.target.querySelector('button');
        deleteBtn.disabled = true;
        const flash = new Flash();
        flash.clear();
        try {
          const form = new FormData(event.target);
          await PricesService.deleteFixedMaterialPrice(form);
          this.refreshClient(form.get('client_id'));
          flash.flash('Deleted', 'success', true);
        } catch (err) {
          console.error(err);
          if (err.response?.data?.errors && err.response.data.errors.length) {
            err.response.data.errors.forEach((errorMessage) => {
              flash.flash(errorMessage, 'error');
            });
          } else {
            flash.flash('Could not delete. Please reload the page and try again', 'error');
          }
        } finally {
          deleteBtn.disabled = false;
        }
      },
      // Material Fixed Price dynamic select menu
      async getOrdersSelectFields() {
        try {
          this.orderConfigLoading = true;
          this.ordersSelectFields = (await OrderService.getOrderSelectFieldsConfig()).order_types;
          this.initMaterialFixedPricesItemsList();
        } catch (err) {
          console.error(err);
          const flash = new Flash('.flash--main');
          flash.flash('Material Fixed Price list loading has failed', 'error');
        } finally {
          this.orderConfigLoading = false;
        }
      },
      initMaterialFixedPricesItemsList() {
        // Order Types are static
        this.listOrderTypes = Object.values(this.ordersSelectFields).map((orderType) => {
          return { value: orderType.id, text: orderType.name, self: orderType };
        });
      },
      getSelectedOrderType() {
        return this.listOrderTypes.find((orderType) => orderType.value == this.materialFixedPriceModel.orderType)?.self;
      },
      getSelectedMaterialType() {
        const orderType = this.getSelectedOrderType();
        if (!orderType) {
          return;
        }
        return Object.values(orderType.select_fields).find(
          (selectField) => selectField.id == this.materialFixedPriceModel.materialType,
        );
      },
      getSelectedMaterial() {
        return (this.priceOverridePlaceholder = this.listPrintMaterial.find(
          (printMaterial) => printMaterial.self.id == this.materialFixedPriceModel.materialName,
        )?.self);
      },
      changeMaterialType() {
        const orderType = this.getSelectedOrderType();
        if (!orderType) {
          this.listMaterialTypes = [];
          // Update downstream
          this.updateMaterialList();
          return;
        }
        this.listMaterialTypes = Object.values(orderType.select_fields)
          // Display only configured material types
          .filter((selectField) => ORDER_MATERIAL_FIELDS.includes(selectField.code))
          .map((selectField) => {
            return { value: selectField.id, text: selectField.name, self: selectField };
          });
        // Update downstream
        this.updateMaterialList();
      },
      updateMaterialList() {
        const materialType = this.getSelectedMaterialType();
        if (!materialType) {
          this.listPrintMaterial = [];
          return;
        }
        const selectedMaterials = this.getClientRef(this.materialFixedPriceModel.clientId).select_field_values;
        this.listPrintMaterial = materialType?.values.map((printMaterial) => {
          return {
            value: printMaterial.id,
            text: printMaterial.value,
            self: printMaterial,
            // Disable already selected materials
            disabled: selectedMaterials.find((selectedMaterial) => selectedMaterial.id == printMaterial.id)
              ? true
              : false,
          };
        });
      },
      updateMaterialPricePlaceholder() {
        this.priceOverridePlaceholder = this.getSelectedMaterial()?.meta?.price;
      },
    },
  });
  app.$mount('#app');
});
