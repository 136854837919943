import axios from 'axios';

export default class ClientService {
  static async listClients(params) {
    const response = await axios.get('/api/v2/clients', {
      params: params,
      paramsSerializer: { indexes: null },
    });

    return response.data.clients.map((client) => {
      return {
        id: client.id,
        crmId: client.crm_id,
        name: client.name,
        contacts: client.contacts.map((contact) => {
          return {
            email: contact.email,
            isPrimary: contact.is_primary,
          };
        }),
      };
    });
  }
}
