import { ORDERS_MAIN_URL, ORDER_NEW_LAST_SELECTED_ORDER_TYPE_LOCAL_STORAGE_KEY } from '../../config';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { localDateTimeToUTCString } from '../../helpers/dates';
import { page } from '../../helpers/page';
import { required } from 'vee-validate/dist/rules';
import { scrollToFirstFormValidationError } from '../../helpers/veevalidate';
import ClientSelectField from '../../components/ClientSelectField.vue';
import Flash from '../../helpers/Flash';
import OrderService from '../../services/OrderService';
import SecurityService from '../../services/SecurityService';
import UserService from '../../services/UserService';
import Vue from 'vue';
import Vuetify from '../../helpers/vuetify';

page('/orders/new', function () {
  const flash = new Flash('.flash--main');

  const app = new Vue({
    vuetify: Vuetify,
    delimiters: ['[[', ']]'],
    components: {
      ValidationProvider,
      ValidationObserver,
      ClientSelectField,
    },

    data() {
      return {
        orderConfig: {},
        orderTypesWithSelectFields: [],

        csrfToken: null,

        isPageLoading: false,
        isOrderSubmitting: false,

        selectedOrderType: {
          id: '3',
          code: 'uv',
          name: 'UV',
        },

        initialClient: { id: null, name: null, contacts: [] },

        order: {
          // Order
          client: null, // Unstructured client name
          clientId: null, // Structured client ID
          deadline: null,
          manager: null,
          priority: 1, // Regular
          status: 1, // Created
          price: null,
          invoiceNumber: null,
          errorDescription: null,
          // Email to the Client
          clientEmail: null,
          title: null,
          sendClientEmails: true,
          // Print
          printSize: null,
          printCount: null,
          printFile: null,
          printMaterial: null,
          printLaminate: null,
          printSide: null,
          printDevice: null,
          postProcessing: [],
          // Order
          notes: null,
        },
      };
    },

    computed: {
      isOrderPriceRequired() {
        return this.selectedOrderType.code === 'digital_a3';
      },

      isErrorDescriptionRequired() {
        return this.order.status == 7; // Error
      },

      isPrintLaminateSelectFieldShown() {
        return ['uv', 'digital_a3', 'large_format'].includes(this.selectedOrderType.code);
      },

      isPrintSideSelectFieldShown() {
        return this.selectedOrderType.code === 'digital_a3';
      },

      isPrintDeviceSelectFieldShown() {
        return this.selectedOrderType.code === 'cutter';
      },

      isPostProcessingFieldShown() {
        return ['uv', 'digital_a3', 'large_format'].includes(this.selectedOrderType.code);
      },

      isErrorDescriptionFieldShown() {
        return this.isErrorDescriptionRequired;
      },
    },

    created() {
      // Custom validation rules
      extend('orderPriceRequired', {
        ...required,
        validate: this.validateOrderPrice,
        message: 'The {_field_} field is required',
      });

      extend('clientEmailsEnabled', {
        ...required,
        validate: this.validateClientEmailsEnabled,
        message: 'The {_field_} field is required when Client Emails are enabled',
      });

      extend('printSideRequired', {
        ...required,
        validate: this.validatePrintSide,
        message: 'The {_field_} field is required',
      });

      extend('printDeviceRequired', {
        ...required,
        validate: this.validatePrintDevice,
        message: 'The {_field_} field is required',
      });

      extend('errorDescriptionRequired', {
        ...required,
        validate: this.validateErrorDescription,
        message: 'The {_field_} field is required for Error Orders',
      });

      // Load last selected Order Type
      this.loadLastSelectedOrderType();

      // Watchers
      this.$watch('selectedOrderType.code', this.updateSelectedOrderType);
    },

    async mounted() {
      this.isPageLoading = true;

      try {
        await Promise.all([
          this.getCsrfToken(),
          this.getOrderConfig(),
          this.getOrderTypesWithSelectFields(),
          this.setManagerToCurrentUser(),
        ]);

        this.updateSelectedOrderType();

        this.isPageLoading = false;
      } catch (err) {
        console.error(err);

        flash.flash('Could not load the page. Please reload the page and try again.', 'error', false);
      }
    },

    methods: {
      loadLastSelectedOrderType() {
        const lastSelectedOrderType = localStorage.getItem(ORDER_NEW_LAST_SELECTED_ORDER_TYPE_LOCAL_STORAGE_KEY);
        if (!lastSelectedOrderType) {
          return;
        }

        try {
          const data = JSON.parse(lastSelectedOrderType);
          if (data?.code) {
            this.selectedOrderType.code = data.code;
          }
        } catch (err) {
          console.error(err);
        }
      },

      updateLastSelectedOrderType() {
        localStorage.setItem(
          ORDER_NEW_LAST_SELECTED_ORDER_TYPE_LOCAL_STORAGE_KEY,
          JSON.stringify(this.selectedOrderType),
        );
      },

      async getCsrfToken() {
        this.csrfToken = await SecurityService.getCsrfToken();
      },

      async getOrderConfig() {
        this.orderConfig = await OrderService.getOrderConfig();
      },

      async getOrderTypesWithSelectFields() {
        this.orderTypesWithSelectFields = (await OrderService.getOrderSelectFieldsConfig()).order_types;
      },

      async setManagerToCurrentUser() {
        this.order.manager = (await UserService.getCurrentUser()).id;
      },

      updateSelectedOrderType() {
        this.$refs.formValidationObserver.reset();

        const orderType = this.orderTypesWithSelectFields[this.selectedOrderType.code];
        this.selectedOrderType.id = orderType.id;
        this.selectedOrderType.name = orderType.name;

        this.updateLastSelectedOrderType();
      },

      getCurrentOrderTypeSelectFieldValues(selectFieldCode) {
        const selectField =
          this.orderTypesWithSelectFields?.[this.selectedOrderType.code]?.['select_fields']?.[selectFieldCode];

        return selectField ? selectField.values : [];
      },

      async submitOrder() {
        flash.clear();

        const isFormValid = await this.$refs.formValidationObserver.validate();
        if (!isFormValid) {
          scrollToFirstFormValidationError(this.$el);
          return;
        }

        this.isOrderSubmitting = true;

        try {
          await OrderService.createNewOrder(
            {
              order_type: Number(this.selectedOrderType.id),

              clientId: this.order.clientId, // Structured client ID
              client: this.order.client || null, // Unstructured client name
              status: this.order.status,
              priority: this.order.priority,
              manager: this.order.manager,
              notes: this.order.notes || null,
              deadline: localDateTimeToUTCString(this.order.deadline),

              error_description: this.order.errorDescription || null,

              send_client_emails: this.order.sendClientEmails,
              client_email: this.order.sendClientEmails ? this.order.clientEmail || null : null,
              title: this.order.sendClientEmails ? this.order.title || null : null,

              price: this.order.price || null,
              invoice_number: this.order.invoiceNumber || null,

              print_size: this.order.printSize,
              print_count: this.order.printCount,
              print_file: this.order.printFile || null,

              print_material: this.order.printMaterial,
              print_side: this.order.printSide,
              print_laminate: this.order.printLaminate,
              print_device: this.order.printDevice,
              post_processing: this.order.postProcessing,
            },
            this.csrfToken,
          );

          window.location.href = ORDERS_MAIN_URL;
        } catch (err) {
          console.error(err);

          window.scrollTo({ top: 0, behavior: 'smooth' });

          if (err.response?.data?.validation_errors) {
            flash.flashValidationErrors(err.response.data.validation_errors);
          } else {
            flash.flash('Could not create the order. Please reload the page and try again.', 'error', true);
          }
        } finally {
          this.isOrderSubmitting = false;
        }
      },

      validateOrderPrice(value) {
        return this.isOrderPriceRequired ? required.validate(value) : true;
      },

      validateClientEmailsEnabled(value) {
        return this.order.sendClientEmails ? required.validate(value) : true;
      },

      validatePrintSide(value) {
        return this.isPrintSideSelectFieldShown ? required.validate(value) : true;
      },

      validatePrintDevice(value) {
        return this.isPrintDeviceSelectFieldShown ? required.validate(value) : true;
      },

      validateErrorDescription(_) {
        const errorDescriptionPlainText = this.$refs.errorDescriptionEditor.quill.getText().trim();

        return this.isErrorDescriptionRequired ? required.validate(errorDescriptionPlainText) : true;
      },

      updateSelectedClient(newClient) {
        this.order.client = newClient.name;

        const primaryEmail = newClient.contacts?.find((contact) => contact.isPrimary)?.email;
        this.order.clientEmail = primaryEmail || null;
      },
    },
  });

  app.$mount('#app');
});
