import { ORDERS_MAIN_URL } from '../../config';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { getOrderIdFromOrderEditPage } from '../../helpers/routeParams';
import { localDateTimeToUTCString, utcDateTimeToLocalDateTimeInputString } from '../../helpers/dates';
import { page } from '../../helpers/page';
import { required } from 'vee-validate/dist/rules';
import { scrollToFirstFormValidationError } from '../../helpers/veevalidate';
import ClientSelectField from '../../components/ClientSelectField.vue';
import Flash from '../../helpers/Flash';
import OrderService from '../../services/OrderService';
import SecurityService from '../../services/SecurityService';
import Vue from 'vue';
import Vuetify from '../../helpers/vuetify';

page(
  '/orders/edit',
  function () {
    const flash = new Flash('.flash--main');

    const app = new Vue({
      vuetify: Vuetify,
      delimiters: ['[[', ']]'],
      components: {
        ValidationProvider,
        ValidationObserver,
        ClientSelectField,
      },

      data() {
        return {
          orderConfig: {},
          orderTypeWithSelectFields: {},

          csrfToken: null,

          isPageLoading: false,
          isOrderSubmitting: false,

          selectedOrderType: {
            id: null,
            code: null,
            name: null,
          },

          initialClient: null,

          order: {
            // Order
            id: null,
            client: null,
            deadline: null,
            manager: null,
            priority: null,
            status: null,
            price: null,
            invoiceNumber: null,
            errorDescription: null,
            // Email to the Client
            clientEmail: null,
            title: null,
            sendClientEmails: true,
            // Print
            printSize: null,
            printCount: null,
            printFile: null,
            printMaterial: null,
            printLaminate: null,
            printSide: null,
            printDevice: null,
            postProcessing: [],
            // Order
            notes: null,
          },
        };
      },

      computed: {
        isOrderPriceRequired() {
          return this.selectedOrderType.code === 'digital_a3';
        },

        isErrorDescriptionRequired() {
          return this.order.status == 7; // Error
        },

        isPrintLaminateSelectFieldShown() {
          return ['digital_a3', 'large_format', 'uv'].includes(this.selectedOrderType.code);
        },

        isPrintSideSelectFieldShown() {
          return this.selectedOrderType.code === 'digital_a3';
        },

        isPrintDeviceSelectFieldShown() {
          return this.selectedOrderType.code === 'cutter';
        },

        isPostProcessingFieldShown() {
          return ['uv', 'digital_a3', 'large_format'].includes(this.selectedOrderType.code);
        },

        isErrorDescriptionFieldShown() {
          return this.isErrorDescriptionRequired;
        },
      },

      created() {
        // Custom validation rules
        extend('orderPriceRequired', {
          ...required,
          validate: this.validateOrderPrice,
          message: 'The {_field_} field is required',
        });

        extend('clientEmailsEnabled', {
          ...required,
          validate: this.validateClientEmailsEnabled,
          message: 'The {_field_} field is required when Client Emails are enabled',
        });

        extend('printSideRequired', {
          ...required,
          validate: this.validatePrintSide,
          message: 'The {_field_} field is required',
        });

        extend('printDeviceRequired', {
          ...required,
          validate: this.validatePrintDevice,
          message: 'The {_field_} field is required',
        });

        extend('errorDescriptionRequired', {
          ...required,
          validate: this.validateErrorDescription,
          message: 'The {_field_} field is required for Error Orders',
        });
      },

      async mounted() {
        this.isPageLoading = true;

        try {
          await Promise.all([
            this.getCsrfToken(),
            this.getOrderConfig(),
            this.getOrderTypeWithSelectFields(),
            this.getOrderForEdit(),
          ]);

          this.isPageLoading = false;
        } catch (err) {
          console.error(err);

          flash.flash('Could not load the page. Please reload the page and try again.', 'error', false);
        }
      },

      methods: {
        async getCsrfToken() {
          this.csrfToken = await SecurityService.getCsrfToken();
        },

        async getOrderConfig() {
          this.orderConfig = await OrderService.getOrderConfig();
        },

        async getOrderTypeWithSelectFields() {
          const orderId = getOrderIdFromOrderEditPage(window.location.href);

          this.orderTypeWithSelectFields = await OrderService.getOrderSelectFieldsConfigForExistingOrder(orderId);
        },

        async getOrderForEdit() {
          const orderId = getOrderIdFromOrderEditPage(window.location.href);

          const order = await OrderService.getOrderById(orderId);

          // Current order type
          this.selectedOrderType.id = order.order_type.id;
          this.selectedOrderType.code = order.order_type.code;
          this.selectedOrderType.name = order.order_type.name;

          // Order details
          this.order.id = order.id;

          this.order.client = order.client;

          this.order.deadline = utcDateTimeToLocalDateTimeInputString(order.deadline);
          this.order.manager = order.manager.id;
          this.order.priority = order.priority.id;
          this.order.status = order.status.id;
          this.order.price = order.price;
          this.order.invoiceNumber = order.invoice_number;

          this.order.errorDescription = order.error_description;

          this.order.clientEmail = order.client_email;
          this.order.title = order.title;
          this.order.sendClientEmails = order.send_client_emails;

          this.order.printSize = order.print_size;
          this.order.printCount = order.print_count;
          this.order.printFile = order.print_file;
          this.order.printMaterial = order.print_material?.id || null;
          this.order.printLaminate = order.print_laminate?.id || null;
          this.order.printSide = order.print_side?.id || null;
          this.order.printDevice = order.print_device?.id || null;
          this.order.postProcessing = order.post_processing?.map((postProcessing) => postProcessing.id) || [];

          this.order.notes = order.notes || null;

          this.initialClient = {
            id: null,
            name: this.order.client,
            contacts: this.order.clientEmail ? [{ email: this.order.clientEmail, isPrimary: true }] : [],
          };
        },

        getCurrentOrderTypeSelectFieldValues(selectFieldCode) {
          const selectField = this.orderTypeWithSelectFields?.['select_fields']?.[selectFieldCode];

          return selectField ? selectField.values : [];
        },

        async submitOrder() {
          flash.clear();

          const isFormValid = await this.$refs.formValidationObserver.validate();
          if (!isFormValid) {
            scrollToFirstFormValidationError(this.$el);
            return;
          }

          this.isOrderSubmitting = true;

          try {
            await OrderService.editOrder(
              {
                id: this.order.id,
                order_type: Number(this.selectedOrderType.id),

                client: this.order.client,
                status: this.order.status,
                priority: this.order.priority,
                manager: this.order.manager,
                notes: this.order.notes || null,
                deadline: localDateTimeToUTCString(this.order.deadline),

                error_description: this.order.errorDescription || null,

                send_client_emails: this.order.sendClientEmails,
                client_email: this.order.sendClientEmails ? this.order.clientEmail || null : null,
                title: this.order.sendClientEmails ? this.order.title || null : null,

                price: this.order.price || null,
                invoice_number: this.order.invoiceNumber || null,

                print_size: this.order.printSize,
                print_count: this.order.printCount,
                print_file: this.order.printFile || null,

                print_material: this.order.printMaterial,
                print_side: this.order.printSide,
                print_laminate: this.order.printLaminate,
                print_device: this.order.printDevice,
                post_processing: this.order.postProcessing,
              },
              this.csrfToken,
            );

            window.location.href = ORDERS_MAIN_URL;
          } catch (err) {
            console.error(err);

            window.scrollTo({ top: 0, behavior: 'smooth' });

            if (err.response?.data?.validation_errors) {
              flash.flashValidationErrors(err.response.data.validation_errors);
            } else {
              flash.flash('Could not edit the order. Please reload the page and try again.', 'error', true);
            }
          } finally {
            this.isOrderSubmitting = false;
          }
        },

        validateOrderPrice(value) {
          return this.isOrderPriceRequired ? required.validate(value) : true;
        },

        validateClientEmailsEnabled(value) {
          return this.order.sendClientEmails ? required.validate(value) : true;
        },

        validatePrintSide(value) {
          return this.isPrintSideSelectFieldShown ? required.validate(value) : true;
        },

        validatePrintDevice(value) {
          return this.isPrintDeviceSelectFieldShown ? required.validate(value) : true;
        },

        validateErrorDescription(_) {
          const errorDescriptionPlainText = this.$refs.errorDescriptionEditor.quill.getText().trim();

          return this.isErrorDescriptionRequired ? required.validate(errorDescriptionPlainText) : true;
        },

        updateSelectedClient(newClient) {
          this.order.client = newClient.name;

          const primaryEmail = newClient.contacts?.find((contact) => contact.isPrimary)?.email;
          this.order.clientEmail = primaryEmail || null;
        },
      },
    });

    app.$mount('#app');
  },
  true,
);
