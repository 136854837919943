export const getOrderIdFromOrderEditPage = function (url) {
  const urlObj = new URL(url);

  const orderId = urlObj.pathname.split('/')[3];
  if (!orderId) {
    throw new Error('Order ID not found');
  }

  return orderId;
};

export const getOrderEditExternalDetails = function (url) {
  const urlObj = new URL(url);

  const orderId = urlObj.pathname.split('/')[4];
  if (!orderId) {
    throw new Error('Order ID not found');
  }

  const secretKey = urlObj.searchParams.get('secret_key') || urlObj.pathname.split('/')[5];
  if (!secretKey) {
    throw new Error('Secret Key not found');
  }

  return { orderId, secretKey };
};
